import { gql } from "@apollo/client";

export const subscribeEvent =
  gql(`mutation signupEvent($idEvent: ID!, $idFunction: ID, $idFunctionLevel2: ID, $input: UserSignupInput) {
  signupEvent(idEvent: $idEvent, idFunction: $idFunction, idFunctionLevel2: $idFunctionLevel2, input: $input)
}`);

export const updateSubscription =
  gql(`mutation updateSubscription($idEvent: ID!, $idFunction: ID, $input: UserSignupInput) {
  updateSubscription(idEvent: $idEvent, idFunction: $idFunction, input: $input)
}`);

export const deleteSubscription = gql(
  `mutation deleteSubscription($idEvent: ID!, $idFunctionOnline: ID, $idFunctionOnsite: ID) {
    deleteSubscription(idEvent: $idEvent, idFunctionOnline: $idFunctionOnline, idFunctionOnsite: $idFunctionOnsite)
  }`
);

const defaultObj = { subscribeEvent, updateSubscription, deleteSubscription };

export default defaultObj;
