import captureException from "helpers/sentryHelper";
import { compareTwoDate, getDifferencesInSeconds } from "helpers/datehelper";
import { EventFunction, Event } from "components/Event/types";
import {
  AgendaEvent,
  AgendaFunction,
} from "controllers/agenda/hooks/useAgenda/agenda";
import { CertificationsFunction } from "controllers/certifications/hooks/useCertifications/certifications";

/**
 * Prende in input una stringa con codice html e ritorna la stringa senza tags html
 * @param html Strippa i tags html
 * @returns stringa senza i tag html
 */
const stringHtmlTags = (html: string): string => {
  try {
    if (!html) {
      return "";
    }
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  } catch (err) {
    captureException({
      key: "component",
      value: "stringHtmlTags",
      error: err,
      extra: null,
    });
    console.error(err.message);
    return "";
  }
};

const shorten = (str: string, maxCharacters: number, separator = " ") => {
  if (str.length <= maxCharacters) {
    return str;
  }
  return str.substring(0, str.lastIndexOf(separator, maxCharacters));
};

/**
 * Trova il contenuto in input ad un massimo numero di caratteri
 * @param content string
 * @param maxCharacters int
 * @returns string
 */
export const truncateAbstract = (content: string, maxCharacters: number) => {
  if (!content || content?.length <= maxCharacters) {
    return content;
  }
  let truncateContent;
  for (let i = 0; i < maxCharacters; i += 3) {
    truncateContent = shorten(content, i);
  }
  return `${truncateContent}...`;
};

/**
 * Ordina gli eventi in ingresso
 * @param events Array di eventi
 * @returns Ritorna un array di eventi ordinati
 */
export const orderEventFunctions = (
  events: Array<
    | EventFunction
    | Event
    | AgendaEvent
    | AgendaFunction
    | CertificationsFunction
  >,
  isManifestation = false,
  isPastManifestation = false
): Array<
  EventFunction | Event | AgendaEvent | AgendaFunction | CertificationsFunction
> => {
  const orderedEvents = [...events];
  return orderedEvents.sort(
    (
      eventA:
        | EventFunction
        | Event
        | AgendaEvent
        | AgendaFunction
        | CertificationsFunction,
      eventB:
        | EventFunction
        | Event
        | AgendaEvent
        | AgendaFunction
        | CertificationsFunction
    ) => {
      const comparation = compareTwoDate(eventA.startDate, eventB.startDate);
      // Se gli eventi non hanno la stessa data di inizio o sto ordinando le manifestazioni allora ritorno il risultato del compare
      if (comparation !== 0 || isManifestation) {
        // Se sto ordinando le manifestazioni passate, allora devo ordinarle al contrario ( le più recenti prima )
        if (isManifestation && isPastManifestation) {
          return comparation === 1 ? -1 : 1;
        }
        return comparation;
      }

      // altrimenti controllo la durata, bisogna mostrare prima l'evento con durata inferiore
      const durationA = getDifferencesInSeconds(
        eventA.endDate,
        eventA.startDate
      );
      const durationB = getDifferencesInSeconds(
        eventB.endDate,
        eventB.startDate
      );
      return durationA >= durationB ? 1 : -1;
    }
  );
};

/**
 * Ritorna true se la stringa tornata è un absolute url, altrimenti false
 * @param url stringa
 * @returns {boolean} risultato del check
 */
export const isUrlAbsolute = (url) => {
  const regularExpressionForURL = /^https?:\/\//i;
  const isAbsolute = regularExpressionForURL.test(url);
  if (isAbsolute) {
    captureException({
      key: "component",
      value: "isUrlAbsolute",
      error: "Url absolute in query string",
      extra: {
        url,
      },
    });
  }
  return isAbsolute;
};

export default stringHtmlTags;
