import { useEffect, useRef } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import getProfileInfo from "controllers/profilo/graphql/queries";
import { useQuery } from "@apollo/client";
import { RegistrationData } from "components/Forms/types";
import { useAuth } from "context/Auth";
import { compareAsc } from "date-fns";
import captureException from "helpers/sentryHelper";
import { comuni, nazioni } from "components/Forms/paesi";

let schema = yup.object({
  firstName: yup.string().nullable().required("Campo richiesto."),
  lastName: yup.string().nullable().required("Campo richiesto."),
  nazione: yup.string().nullable().required("Campo richiesto."),
  city: yup.string().when("nazione", {
    is: (nazione) => nazione && nazione === "ITALIA",
    then: yup.string().nullable().required("Campo richiesto."),
    otherwise: yup.string().nullable(),
  }),
  phone: yup.string().nullable().required("Campo richiesto."),
  company: yup.string().nullable().required("Campo richiesto."),
  settore: yup.string().nullable().required("Campo richiesto."),
  tipologia: yup.string().when("settore", {
    is: (settore) =>
      settore && !["Terzo Settore", "Non Occupato/Altro"].includes(settore),
    then: yup.string().nullable().required("Campo richiesto."),
    otherwise: yup.string().nullable(),
  }),
  qualifica: yup.string().when("settore", {
    is: (settore) => settore && settore !== "Non Occupato/Altro",
    then: yup.string().nullable().required("Campo richiesto."),
    otherwise: yup.string().nullable(),
  }),
  area: yup.string().when("settore", {
    is: (settore) =>
      settore && !["Terzo Settore", "Non Occupato/Altro"].includes(settore),
    then: yup.string().nullable().required("Campo richiesto"),
    otherwise: yup.string().nullable(),
  }),
  privacyTerzi: yup.bool().required(),
});

export interface componentProps {
  dataEvent?: {
    function: {
      sessionType: string;
      idOnline: string;
      idOnsite: string;
      id: string;
      registration: string;
      registrationOnline: string;
      registrationOnsite: string;
      usage: string;
    };
  };
  isAccreditation?: boolean;
  idFunctionSubscribed?: {
    idOnline?: string;
    idOnsite?: string;
    lastSubscription?: string;
    loaded: boolean;
    relator?: boolean;
  };
}

const subscriptionFormHook = ({
  isAccreditation,
  dataEvent,
  idFunctionSubscribed,
}) => {
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    resetField,
    reset,
    setValue,
  } = useForm<RegistrationData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: user?.attributes?.email || "",
      city: "",
      nazione: "",
      company: "",
      firstName: user?.attributes?.given_name || "",
      lastName: user?.attributes?.family_name || "",
      phone: "",
      privacyTerzi: false,
      qualifica: "",
      settore: "",
      tipologia: "",
      area: "",
    },
  });

  const {
    data: profileData,
    loading: loadingProfileData,
    error: errorProfileData,
  } = useQuery(getProfileInfo, {
    onError: (errorQuery) =>
      captureException({
        key: "component",
        value: "subscriptionFormHookUseQuery",
        error: errorQuery,
        extra: null,
      }),
  });

  useEffect(() => {
    const checkOldFieldCity = (
      data
    ): {
      nazione?: string;
      city?: string;
    } => {
      // Se nazione è salvata, quindi ho già compilato il nuovo form, allora esco.
      // ritornando i valori attuali.
      if (data.profile.nazione) {
        return {
          nazione: data.profile.nazione,
          city: data.profile.city,
        };
      }
      // Se il vecchio campo comune fa parte dei comuni allora mostro entrambe le select (Nazione e Comune) precompilate.
      if (
        data.profile.city &&
        comuni.includes(data.profile.city.toUpperCase())
      ) {
        return {
          nazione: "ITALIA",
          city: data.profile.city,
        };
      }

      // Se il vecchio campo comune fa parte delle nazioni allora mostro solamente la select delle nazioni
      // precompilandola con il vecchio campo.
      if (
        data.profile.city &&
        nazioni.includes(data.profile.city.toUpperCase())
      ) {
        return {
          nazione: data.profile.city,
        };
      }

      return {
        nazione: data.profile.nazione,
        city: data.profile.city,
      };
    };

    if (!loadingProfileData && profileData?.profile) {
      const { nazione, city } = checkOldFieldCity(profileData);
      const { __typename, ...data } = profileData.profile;
      reset(
        {
          email: data.email || user?.attributes?.email,
          firstName: data.firstName || user?.attributes?.given_name,
          lastName: data.lastName || user?.attributes?.family_name,
          company: data.company,
          nazione,
          city,
          phone: data.phone,
          settore: data.settore,
          tipologia: data.tipologia,
          area: data.area,
          qualifica: data.qualifica,
          privacyTerzi: data.privacyTerzi ?? false,
        },
        {
          keepDefaultValues: true,
        }
      );
    }
  }, [loadingProfileData, profileData]);

  // eslint-disable-next-line prefer-const
  let [currentMacroSettore, currentNazione, currentComune] = watch([
    "settore",
    "nazione",
    "city",
  ]);

  // Al primo render setto currentMacroSettore al valore già presente nel profilo utente
  if (typeof currentMacroSettore === "undefined") {
    currentMacroSettore = profileData?.profile?.settore;
  }

  // Al primo render setto currentNazione al valore già presente nel profilo utente
  if (typeof currentNazione === "undefined") {
    currentNazione = profileData?.profile?.nazione ?? "";
  }

  // Stabilisco una variabile oldMacroSettore per aiutarmi con il reset dei campi dipendenti dal settore
  const oldMacroSettore = useRef(profileData?.profile?.settore);

  useEffect(() => {
    // Resetto il campo tipologia,qualifica e area ogni volta che il settore cambia, treanne al primo render e quando viene settato al valore iniziale
    if (
      typeof oldMacroSettore.current === "undefined" ||
      currentMacroSettore === profileData?.profile?.settore
    ) {
      // Aggiorno l'old macro settore
      oldMacroSettore.current = currentMacroSettore;
      return;
    }
    resetField("tipologia");
    resetField("qualifica");
    resetField("area");

    // Aggiorno l'old macro settore
    oldMacroSettore.current = currentMacroSettore;
  }, [currentMacroSettore]);

  const resetComune = useRef(false);
  useEffect(() => {
    // Resetto il comune ogni volta che modifico la nazione, ma non lo resetto la prima volta che entro.
    // Se lo resettassi anche la prima volta, quando faccio un iscrizione, se subito dopo vado a modificarla vedrei
    // il campo Comune vuoto.
    if (!resetComune.current) {
      resetComune.current = true;
      return;
    }
    if (currentNazione !== "ITALIA") {
      setValue("city", "");
    }
  }, [currentNazione]);

  const typesRegistration: Array<{
    label: string;
    value: string;
    disabled?: boolean;
  }> = [];

  // Se è un form di accredito forzo idFunction come campo non obbligatorio, altrimenti
  // quando passo da un form di iscrizione evento ad uno di accredito questo risulterebbe obbligatorio
  if (isAccreditation) {
    schema = schema.shape({
      idFunction: yup.string(),
    });
  }
  if (!isAccreditation) {
    // se l'evento è concluso chiudo le iscrizioni
    const eventIsEnded = compareAsc(
      new Date(dataEvent?.function?.endDate),
      new Date()
    );

    if (eventIsEnded < 0) {
      return { isClosed: true };
    }

    // Se mi sto iscrivendo ad un evento il campo idFunction (Modalità di partecipazione) deve essere obbligatorio
    schema = schema.shape({
      idFunction: yup.string().required("Campo richiesto."),
    });

    // Se usage non è valorizzato con Event, chiudo il form.
    if (dataEvent.function?.usage !== "Event") {
      return { isClosed: true };
    }

    // L'evento deve avere uno dei due campi registrationOnline e registrationOnsite valorizzati a true.
    // Se nessuno dei due campi registration è valorizzato a true, chiudo il form.

    // Controllo anche che l'utente si sia mai registrato in precedenza a quella modalità, se si, devo dargli la possibilità
    // di modificare l'iscrizione e quindi tenere visibile la modalità nella select.
    if (
      dataEvent.function.idOnline &&
      ((dataEvent.function?.registrationOnline &&
        ["ibrido", "online"].includes(dataEvent.function?.sessionType)) ||
        dataEvent.function.idOnline === idFunctionSubscribed.lastSubscription)
    ) {
      typesRegistration.push({
        label: "Online",
        value: dataEvent.function.idOnline,
      });
    }

    if (
      dataEvent.function.idOnsite &&
      ((dataEvent.function?.registrationOnsite &&
        ["ibrido", "in presenza"].includes(dataEvent.function?.sessionType)) ||
        dataEvent.function.idOnsite === idFunctionSubscribed.lastSubscription)
    ) {
      typesRegistration.push({
        label: "In presenza",
        value: dataEvent.function.idOnsite,
      });
    }

    // Se l'evento è ibrido allora aggiungo una option disabilitata
    if (typesRegistration.length === 2) {
      typesRegistration.splice(0, 0, {
        label: "Seleziona modalità di partecipazione",
        value: "",
        disabled: true,
      });
    }

    if (typesRegistration.length === 0) {
      return { isClosed: true };
    }
  }

  return {
    typesRegistration,
    register,
    handleSubmit,
    loadingProfileData,
    errorProfileData,
    profileData,
    errors,
    currentMacroSettore,
    currentNazione,
    currentComune,
    setValue,
  };
};

export default subscriptionFormHook;
