import {
  getDate,
  getYear,
  isSameDay,
  format,
  eachDayOfInterval,
  isWithinInterval,
  compareAsc,
  differenceInSeconds,
} from "date-fns";
import captureException from "helpers/sentryHelper";

const { it } = require("date-fns/locale");

const convertEventDate = (
  dateStart: string,
  dateEnd
): {
  day: string;
  time?: string;
} => {
  const dateStartObject = new Date(dateStart);
  const dateEndObject = new Date(dateEnd);

  // non posso utilizzare un unico format per day month e year perchè ho bisogno di modificare in maiuscola la prima
  // lettera del mese, la localizzazione italiana ritorna l'intero mese in minuscolo
  const dayStart = getDate(dateStartObject);
  const monthStartLowercase = format(dateStartObject, "MMMM", { locale: it });
  const monthStart = `${monthStartLowercase
    .charAt(0)
    .toUpperCase()}${monthStartLowercase.slice(1)}`;
  const yearStart = getYear(dateStartObject);
  const timeStart = format(dateStartObject, "HH:mm", { locale: it });

  const dayEnd = getDate(dateEndObject);
  const monthEndLowercase = format(dateEndObject, "MMMM", { locale: it });
  const monthEnd = `${monthEndLowercase
    .charAt(0)
    .toUpperCase()}${monthEndLowercase.slice(1)}`;
  const yearEnd = getYear(dateEndObject);
  const timeEnd = format(dateEndObject, "HH:mm", { locale: it });

  // Se il giorno dell'inizio evento è lo stesso del giorno di fine, ritorno giorno e ora
  if (isSameDay(dateStartObject, dateEndObject)) {
    return {
      day: `${dayStart} ${monthStart} ${yearStart}`,
      time: `dalle ${timeStart} alle ${timeEnd}`,
    };
  }

  // altrimenti l'evento dura più giorni, quindi ritorno solamente il giorno di inizio e quello di fine senza orario
  return {
    day: `${dayStart} ${monthStart !== monthEnd ? monthStart : ""} ${
      yearStart !== yearEnd ? yearStart : ""
    } - ${dayEnd} ${monthEnd} ${yearEnd}`.trim(),
  };
};

/**
 * Dati in input l'inizio e la fine di un evento ritorna un array di date
 * che rappresentano i giorni nell'intervallo
 *
 * @param {string} startDate
 * @param {string} endDate
 * @returns Array<Date> | null
 */
export const getIntervalBetweenTwoDates = (
  startDate: string,
  endDate: string
): Array<Date> | null => {
  if (!startDate || !endDate) {
    return null;
  }
  return eachDayOfInterval({
    start: new Date(startDate),
    end: new Date(endDate),
  });
};

export const formatDate = (formatType: string, date: Date): string =>
  format(date, formatType, { locale: it });

export const isInInterval = (
  start: Date,
  end: Date,
  dateToCheck: Date
): boolean => isWithinInterval(dateToCheck, { start, end });

export const formatFromString = (
  dateString: string,
  dateFormat: string
): string | null => {
  try {
    const dateObj = new Date(dateString);
    return format(dateObj, dateFormat, {
      locale: it,
    });
  } catch (error) {
    captureException({
      key: "component",
      value: "formatFromString",
      error,
      extra: {
        dateString,
        dateFormat,
      },
    });
    console.error(error.message);
    return null;
  }
};

/**
 * Compara due date e ritorna 1 se la prima è maggiore dell'altra, -1 se la seconda è maggiore della prima, 0 se sono uguali
 *
 * @param {string} dateA prima data da comparare
 * @param {string} dateB seconda data da comparare
 * @returns Int
 */
export const compareTwoDate = (dateA: string, dateB: string) => {
  try {
    return compareAsc(new Date(dateA), new Date(dateB));
  } catch (err) {
    captureException({
      key: "component",
      value: "compareTwoDate",
      error: err,
      extra: {
        dateA,
        dateB,
      },
    });
    return 0;
  }
};

/**
 * Ritorna la differenza in secondi tra due date
 * @param {string} endDate data di fine
 * @param {string} startDate data di inizio
 * @return Int differenza in secondi tra le due date
 */
export const getDifferencesInSeconds = (endDate: string, startDate: string) => {
  try {
    return differenceInSeconds(new Date(endDate), new Date(startDate));
  } catch (err) {
    captureException({
      key: "component",
      value: "getDifferencesInSeconds",
      error: err,
      extra: {
        endDate,
        startDate,
      },
    });
    return 0;
  }
};

export default convertEventDate;
