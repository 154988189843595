import React, { MouseEventHandler } from "react";
import { SubmitHandler } from "react-hook-form";
import Input from "components/Forms/Input";
import Autocomplete from "components/Forms/Autocomplete";
import Select from "components/Forms/Select";
import Button from "components/Forms/Button";
import clsx from "clsx";
import Loader from "components/Loader";
import {
  Qualifica,
  Settore,
  SettorePubblico,
  SettorePrivato,
  AreaPrivata,
  AreaPubblica,
} from "components/Forms/sfdcValues";
import Checkbox from "components/Forms/Checkbox";
import subscriptionFormHook from "controllers/event/hooks/subscriptionFormHook";
import { RegistrationData } from "components/Forms/types";
import { comuni, nazioni } from "components/Forms/paesi";

export interface componentProps {
  dataEvent?: {
    function: {
      sessionType: string;
      idOnline: string;
      idOnsite: string;
      id: string;
      registration: string;
      registrationOnline: string;
      registrationOnsite: string;
      usage: string;
    };
  };
  subscribeToEvent: SubmitHandler<RegistrationData>;
  loading;
  isAccreditation?: boolean;
  cancelAction?: Function;
  editMode?: boolean;
  idFunctionSubscribed?: {
    idOnline?: string;
    idOnsite?: string;
    lastSubscription?: string;
  };
  // id delle funzioni che sono state chiuse mentre l'utente era nella pagina di registrazione e ha provato ad iscriversi
  idFunctionsClosed?: string[];
}

function SubscriptionForm({
  dataEvent,
  subscribeToEvent,
  loading,
  isAccreditation,
  cancelAction,
  editMode,
  idFunctionSubscribed,
  idFunctionsClosed,
}: componentProps) {
  const {
    typesRegistration,
    register,
    handleSubmit,
    loadingProfileData,
    errorProfileData,
    profileData,
    errors,
    currentMacroSettore,
    currentNazione,
    currentComune,
    isClosed,
    setValue,
  } = subscriptionFormHook({
    dataEvent,
    isAccreditation,
    idFunctionSubscribed,
  });

  if (loadingProfileData) {
    return <Loader />;
  }

  // idFunctionsClosed contiene gli id delle funzioni che sono state chiuse mentre l'utente era nella pagina di registrazione.
  // Di conseguenza filtro l'array typesRegistration per mostrare solamente le modalità di partecipazione aperte.
  // Rimuovo anche l'opzione "Seleziona la modalità di partecipazione" in stato disabled
  let filteredTypesRegistrations = typesRegistration;
  if (idFunctionsClosed.length > 0) {
    filteredTypesRegistrations = typesRegistration.filter(
      (type) => !idFunctionsClosed.includes(type.value) && !type.disabled
    );
  }

  if (
    isClosed ||
    (filteredTypesRegistrations.length === 0 && !isAccreditation)
  ) {
    return <p>Le registrazioni per questo evento sono chiuse</p>;
  }

  if (errorProfileData || !profileData) {
    return <p>Si è verificato un errore</p>;
  }

  return (
    <form
      className={clsx(
        "space-y-3 pt-5 flex flex-col",
        isAccreditation ? "pb-8" : ""
      )}
      onSubmit={handleSubmit(subscribeToEvent, (errorSubscribe) =>
        console.error(errorSubscribe)
      )}
    >
      {!isAccreditation && (
        <p className="text-base font-bold">
          {editMode ? "Modifica dati di iscrizione" : "Iscriviti all'evento"}
        </p>
      )}
      <p className="subtitle2 text-gray-dark">
        Leggi l&apos;
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.networkdigital360.it/privacy?__hstc=44338619.29fe862c7835f4f659bcad34cd98ffa4.1614333474957.1618227012708.1618560166137.5&__hssc=443386
          19.3.1618560166137&__hsfp=3716040236"
        >
          informativa sulla privacy
        </a>
      </p>
      <p className="subtitle2 text-gray-dark">
        Si informano gli utenti che durante l’evento verranno effettuate riprese
        foto/videografiche per fini informativi e promozionali relativi
        all’evento stesso, come meglio indicato nell’
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://access.networkdigital360.it/hubfs/FPA/FORUM%20PA_Informativa%20immagini%20visitatori.pdf"
        >
          informativa privacy
        </a>
      </p>
      <Input
        name="email"
        label="Email *"
        className="w-full bg-gray-200"
        type="email"
        disabled
        register={register}
        error={errors?.email?.message}
      />
      {!isAccreditation && (
        <Select
          className="w-full"
          label="Modalità di partecipazione *"
          register={register}
          name="idFunction"
          values={filteredTypesRegistrations}
          error={errors?.idFunction?.message}
          defaultValue={
            idFunctionSubscribed.lastSubscription ??
            filteredTypesRegistrations[0].value
          }
        />
      )}
      <Input
        register={register}
        className="w-full"
        label="Nome *"
        name="firstName"
        type="text"
        placeholder="Nome"
        error={errors?.firstName?.message}
      />
      <Input
        register={register}
        className="w-full"
        label="Cognome *"
        name="lastName"
        type="text"
        placeholder="Cognome"
        error={errors?.lastName?.message}
      />
      <Select
        register={register}
        className="w-full"
        name="settore"
        label="Settore *"
        values={Settore}
        error={errors?.settore?.message}
      />
      {currentMacroSettore &&
        !["Non Occupato/Altro", "Terzo Settore"].includes(
          currentMacroSettore
        ) && (
          <Select
            register={register}
            className="w-full"
            name="tipologia"
            label="Tipologia Azienda *"
            values={
              currentMacroSettore === "Pubblico"
                ? SettorePubblico
                : SettorePrivato
            }
            error={errors?.tipologia?.message}
          />
        )}
      <Input
        register={register}
        className="w-full"
        label="Azienda *"
        name="company"
        type="text"
        placeholder="Ente/Azienda"
        error={errors?.company?.message}
      />
      {currentMacroSettore && currentMacroSettore === "Privato" && (
        <Input
          register={register}
          className="w-full"
          label="Job Title *"
          name="qualifica"
          type="text"
          placeholder="Qualifica"
          error={errors?.qualifica?.message}
        />
      )}
      {currentMacroSettore &&
        !["Non Occupato/Altro", "Privato"].includes(currentMacroSettore) && (
          <Select
            register={register}
            className="w-full"
            name="qualifica"
            label="Job Title *"
            values={Qualifica}
            error={errors?.qualifica?.message}
          />
        )}
      {currentMacroSettore &&
        !["Non Occupato/Altro", "Terzo Settore"].includes(
          currentMacroSettore
        ) && (
          <Select
            register={register}
            className="w-full"
            name="area"
            label="Area *"
            values={
              currentMacroSettore === "Pubblico" ? AreaPubblica : AreaPrivata
            }
            error={errors?.area?.message}
          />
        )}
      <Autocomplete
        suggestions={nazioni}
        register={register}
        className="w-full"
        name="nazione"
        label="Nazione *"
        placeholder="Nazione"
        setValue={setValue}
        value={currentNazione ? currentNazione.toUpperCase() : ""}
        error={errors?.nazione?.message}
      />
      {currentNazione && currentNazione === "ITALIA" && (
        <Autocomplete
          suggestions={comuni}
          register={register}
          className="w-full"
          name="city"
          label="Comune *"
          placeholder="Comune"
          setValue={setValue}
          value={currentComune?.toUpperCase()}
          error={errors?.city?.message}
        />
      )}
      <Input
        register={register}
        className="w-full"
        label="Telefono *"
        name="phone"
        type="tel"
        placeholder="Telefono"
        error={errors?.phone?.message}
      />
      {/* Nascondo i campi consenso se l'utente li ha già accettati */}
      {!profileData?.profile?.privacyTerzi && (
        <p className="text-gray-dark font-semibold text-xs col-span-2">
          Consenso facoltativo alla comunicazione dati a terzi
        </p>
      )}
      <Checkbox
        containerClassname={`col-span-2 flex text-sm ${
          profileData?.profile?.privacyTerzi ? "hidden" : ""
        }`}
        register={register}
        name="privacyTerzi"
        error={errors?.privacyTerzi?.message}
      >
        Acconsento alla comunicazione dei miei dati a terzi operanti nel settore
        della consulenza e dei servizi (in particolare nel settore ICT),
        dell’innovazione, del Digitale e del Tech, manufatturiero e del
        commercio e della Pubblica Amministrazione, per permettere a tali
        società di inviare materiale promozionale o comunicazioni commerciali e
        compiere ricerche di mercato tramite mezzi tradizionali o sistemi
        automatizzati di chiamata od altri sistemi di comunicazione a distanza.
      </Checkbox>
      <p className="mt-4 text-sm no-space-y">
        L&apos;elenco dei partner delle Contitolari, a titolo esaustivo e non
        esclusivo, è disponibile{" "}
        <a
          href="https://www.forumpa.it/partner/?event=124720"
          target="_blank"
          rel="noopener noreferrer"
        >
          qui
        </a>
      </p>
      {isAccreditation ? (
        <div className="flex justify-between absolute px-8 py-2 bottom-0 w-full left-0 border-t border-white-dark">
          <Button
            className="btn btn-secondary"
            label="Annulla"
            onClick={cancelAction as MouseEventHandler}
            type="button"
          />
          <Button
            loading={loading}
            className="self-end btn btn-primary"
            type="submit"
            label="Iscriviti"
          />
        </div>
      ) : (
        <div
          className={`bg-white fixed md:sticky px-4 md:px-8 py-2 ${
            editMode ? "flex justify-between" : "text-center md:text-right"
          } bottom-0 w-full md:w-[calc(100%+64px)] md:ml-[-32px] md:mr-[-32px] left-0 border-t border-white-dark`}
        >
          {editMode && (
            <Button
              className={`btn btn-secondary ${
                editMode ? "btn-small" : "w-full"
              } md:w-auto`}
              type="button"
              label="Annulla modifiche"
              onClick={cancelAction as MouseEventHandler}
            />
          )}
          <Button
            loading={loading}
            className={`btn btn-primary ${
              editMode ? "btn-small" : "w-full"
            } md:w-auto flex-none`}
            type="submit"
            label={editMode ? "Salva modifiche" : "Iscriviti"}
          />
        </div>
      )}
    </form>
  );
}

SubscriptionForm.defaultProps = {
  idFunctionsClosed: [],
};

export default SubscriptionForm;
