/* eslint-disable */
// Disabilito i controlli di eslint per falsi positivi
// Segnala che RegistrantType è già utilizzato e che i valori dell'enum non sono mai utilizzate
export enum RegistrantType {
  ALLREG,
  FPG,
  FPA,
  SCU,
  FPE,
}
/* eslint-enable */
