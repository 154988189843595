import { gql } from "@apollo/client";

const getRegistrationInfo =
  gql(`query getRegistrationEventInfo($idEvent: ID!, $idFunction: ID!) {
  event: getEvent(idEvent: $idEvent) {
    class
    creditFunctionId
    public
    statusCode
    title
    type
    startDate
    endDate
    contentDescription
    id
  }

  function: getFunction(idEvent: $idEvent, idFunction: $idFunction) {
    class
    id
    idOnline
    idOnsite
    parentId
    statusCode
    usage
    sessionType
    startDate
    endDate
    description
    note
    registration
    registrationOnline
    registrationOnsite
    fpaCode
    topic
    link
  }
}`);

const getSubscription =
  gql(`query getSubscriptionInfo($idEvent: ID!, $idFunction: ID!) {
  subscription: getSubscription(idEvent: $idEvent, idFunction: $idFunction) {
    idEvent
    idFunction
    sk
    relator
  }
}`);

const getSubscriptionOnlineAndOnsite =
  gql(`query getSubscriptionInfo($idEvent: ID!, $idFunctionOnline: ID!, $idFunctionOnsite: ID!) {
  subscriptionOnline: getSubscription(idEvent: $idEvent, idFunction: $idFunctionOnline) {
    idEvent
    idFunction
    updatedAt
    sk
    relator
  }
  subscriptionOnsite: getSubscription(idEvent: $idEvent, idFunction: $idFunctionOnsite) {
    idEvent
    idFunction
    updatedAt
    sk
    relator
  }
}`);

const listEvents =
  gql(`query listEvents($filterDateFuture: FilterDate, $filterDatePast: FilterDate, $onlyEvents: Boolean) {
    events: listEvents(filterDate: $filterDateFuture) {
      creditFunctionId
      endDate
      startDate
      title
      contentDescription
      id
      link
      logo
    }
    oldEvents: listEvents(filterDate: $filterDatePast) {
      creditFunctionId
      endDate
      startDate
      title
      contentDescription
      id
      link
      logo
    }
    registeredEvents: listSubscriptions(onlyEvents: $onlyEvents) {
      idEvent
      sk
      relator
    }
  }`);

const getEventDetails =
  gql(`query getEventDetails($idEvent: ID!, $levels: [String]) {
  event: getEvent(idEvent: $idEvent) {
    title
    startDate
    endDate
    contentDescription
    id
    link
    space
    logo
    payoff
    subtitle
  }
  functions: listFunctions(idEvent: $idEvent, levels: $levels) {
    description
    startDate
    endDate
    sessionType
    id
    idOnline
    idOnsite
    fpaCode
    note
    link
    registrationOnline
    registrationOnsite
    usage
  }
  registeredEvents: listSubscriptions(idEvent: $idEvent) {
    idFunction
    idEvent
    badge
    sk
    relator
  }
}`);

export {
  getRegistrationInfo,
  getSubscription,
  getSubscriptionOnlineAndOnsite,
  listEvents,
  getEventDetails,
};
